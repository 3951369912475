/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Sortiment"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-v45pmf --style2 --full --parallax" name={"w4ltu0cplf9"} style={{"paddingTop":0,"paddingBottom":0}} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/1e481c86972240bca0ee7aacbc53367b_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/1e481c86972240bca0ee7aacbc53367b_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/1e481c86972240bca0ee7aacbc53367b_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/1e481c86972240bca0ee7aacbc53367b_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/1e481c86972240bca0ee7aacbc53367b_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/1e481c86972240bca0ee7aacbc53367b_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/1e481c86972240bca0ee7aacbc53367b_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/1e481c86972240bca0ee7aacbc53367b_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --center pb--60 pt--60" anim={"7"}>
              
              <Title className="title-box fs--86 title-box--invert" content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">Sortiment</span><br>"}>
              </Title>

              <Text className="text-box fs--20 text-box--invert" style={{"maxWidth":650}} content={"Výrobní a obchodní program firmy GeneralPack s.r.o.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"btyi639vps4"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--48 lh--14" style={{"maxWidth":467}} content={"<span style=\"font-weight: bold;\">Jsme specialisté</span> na obalové materiály z PE fólií&nbsp;"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":334}} content={"<span style=\"color: var(--color-custom-2);\">►</span> 100% LDPE obalových materiálů navrhujeme zákazníkovi na míru v testovací laboratoři a testy v provozech<br><br><span style=\"color: var(--color-custom-2);\">►</span> Pro výrobu LDPE obalových materiálů používáme pouze špičkové suroviny (primární i recyklovanou) a aditiva od renomovaných výrobců"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":334}} content={"<span style=\"color: var(--color-custom-2);\">►</span> Výroba LDPE obalových materiálů probíhá na nejmodernějších strojích vybavených mimo jiné špičkovou technologií pro přesné dávkování jednotlivých složek receptury<br><br><span style=\"color: var(--color-custom-2);\">►</span> Všechny LDPE obalové materiály jsou plně recyklovatelné"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"cgfiqryjgkd"} style={{"paddingTop":0,"paddingBottom":50}}>
          
          <ColumnWrap className="column__flex el--4" animS={"2"} columns={"4"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/76facbd5557045e79c67df89cf4a05ad_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/76facbd5557045e79c67df89cf4a05ad_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/76facbd5557045e79c67df89cf4a05ad_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/76facbd5557045e79c67df89cf4a05ad_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/26565/76facbd5557045e79c67df89cf4a05ad_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Title className="title-box fs--30" style={{"marginTop":35}} content={"Sáčky<br>a Pytle<br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-custom-2);\">Provedení:</span><br>kusové, balení v kartonu, rolované, v blocích, se záložkou, ploché, laminované, s potiskem, se ZIPem"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/o/26565/e0becf8f7f724bb587d750b2a5640854.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={""} position={null}>
              </Image>

              <Title className="title-box fs--30" content={"Paletizační pytle<br>a Stretch-hood<br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-custom-2);\">Paletizační pytle v provedení:<br></span>kusové, rolované, paletizační hadice, s potiskem<br><span style=\"color: var(--color-custom-2);\">Strech-hood:</span> Pro fixaci zboží na paletě bez tepelného smrštění, UV stabilizace i UV filtr, s potiskem<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/1144c9bd34694948945beb9a3cc46aa6_s=660x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/1144c9bd34694948945beb9a3cc46aa6_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/1144c9bd34694948945beb9a3cc46aa6_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/1144c9bd34694948945beb9a3cc46aa6_s=860x_.jpeg 860w"} position={null}>
              </Image>

              <Title className="title-box fs--30" content={"Krycí<br>fólie<br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-custom-2);\">Provedení:</span><br>pro automatické linky včetně potisku, pro ruční aplikací s perforací pro odtrhávání jednotlivých listů"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/322921242be845659fb8444f2ae876f9_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/322921242be845659fb8444f2ae876f9_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/322921242be845659fb8444f2ae876f9_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/322921242be845659fb8444f2ae876f9_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/26565/322921242be845659fb8444f2ae876f9_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Title className="title-box fs--30" content={"Fólie<br>do plnících linek<br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-custom-2);\">Provedení:</span><br>pro automatické linky včetně potisku, laminace, zesílení, ve formě ploché fólie, polohadice, hadice<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 mt--40" animS={"2"} columns={"4"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/360199f0a6b948bca00462f323ece056_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/360199f0a6b948bca00462f323ece056_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/360199f0a6b948bca00462f323ece056_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/360199f0a6b948bca00462f323ece056_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/26565/360199f0a6b948bca00462f323ece056_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--left fs--30" content={"Fólie teplem smrštitené<br>"}>
              </Title>

              <Text className="text-box text-box--left mt--08" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-custom-2);\">Provedení:</span> role 25 – 500kg, fólie do automatických balících linek v provedení:<br>ploché fólie, polohadice, hadice, s potiskem<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/211f9181b2ff49d8b4f44500416f03c1_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/211f9181b2ff49d8b4f44500416f03c1_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/211f9181b2ff49d8b4f44500416f03c1_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/211f9181b2ff49d8b4f44500416f03c1_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/26565/211f9181b2ff49d8b4f44500416f03c1_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--left fs--30" content={"ESD antistatické obalové materiály<br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"Ochrana staticky citlivých výrobků zejména v automobilovém průmyslu.<br><span style=\"color: var(--color-custom-2);\">Provedení</span>: pytle, fólie, paletizační pytle&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/894d9d9f41c34c61808702b45041930d_s=350x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/894d9d9f41c34c61808702b45041930d_s=350x_.png 350w"} position={null}>
              </Image>

              <Title className="title-box title-box--left fs--30" content={"VCI antikorozní obalové materiály<br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"Ochrana materiálů proti korozi\n<br><span style=\"color: var(--color-custom-2);\">Provedení</span>: pytle, fólie, paletizační pytle, VCI papírové proložky&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/5c2d1138e3294bf1bf091d1eea5a46b5_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/5c2d1138e3294bf1bf091d1eea5a46b5_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/5c2d1138e3294bf1bf091d1eea5a46b5_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/5c2d1138e3294bf1bf091d1eea5a46b5_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/26565/5c2d1138e3294bf1bf091d1eea5a46b5_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--left fs--30" content={"Výrobky z HDPE  a MDPE fólií <br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"<span style=\"color: var(--color-custom-2);\">Provedení:</span><br>sáčky, pytle, krycí fólie, transparentní i barevné provedení<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"c0itt64aapd"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --left el--4" animS={"2"} style={{"marginTop":45}} columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/41a37954669c4b8f81e36e7d77ceeeca_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/41a37954669c4b8f81e36e7d77ceeeca_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/41a37954669c4b8f81e36e7d77ceeeca_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/41a37954669c4b8f81e36e7d77ceeeca_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/26565/41a37954669c4b8f81e36e7d77ceeeca_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/26565/41a37954669c4b8f81e36e7d77ceeeca_s=2000x_.jpeg 2000w"} position={null}>
              </Image>

              <Title className="title-box title-box--left fs--30" content={"Fixační Streč - průtažné fólie&nbsp;<br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"5 vrstvé fólie pro ruční i strojní aplikace, fólie do automatických linek, nejvyšší možná míra odolnosti proti protržení.<br>Tloušťky od 8 – 30my, průtažnost od 40 - 400%&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/c7218c8b64cc4a788134d5d25fc0b997_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/c7218c8b64cc4a788134d5d25fc0b997_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/c7218c8b64cc4a788134d5d25fc0b997_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/c7218c8b64cc4a788134d5d25fc0b997_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/26565/c7218c8b64cc4a788134d5d25fc0b997_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--left fs--30" content={"Samolepící<br>pásky "}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"Balící pásky na přelepování kartonů a technické pásky pro speciální použití, potisk 1 – 3 barvy, pro ruční i strojní aplikaci<br>Nosič BOPP, papír, vinyl, PVC\nLepidlo hotmelt, solvent, akrylát, &nbsp;technické pásky pro speciální použití&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim3" anim={"3"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/e9e559798aca46e5a2cdad87cbd7b9c1_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/e9e559798aca46e5a2cdad87cbd7b9c1_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/e9e559798aca46e5a2cdad87cbd7b9c1_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/e9e559798aca46e5a2cdad87cbd7b9c1_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/26565/e9e559798aca46e5a2cdad87cbd7b9c1_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--left fs--30" content={"Vázací<br>pásky<br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"Vázací pásky pro ruční i strojní aplikace, polypropylenové PP, &nbsp;polyesterové PET, elastické EPP \nPES, cordstrap, ocelové spony a příslušenství&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/26565/6d95da36729f4bc8925f9096bd52357b_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/26565/6d95da36729f4bc8925f9096bd52357b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/26565/6d95da36729f4bc8925f9096bd52357b_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/26565/6d95da36729f4bc8925f9096bd52357b_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/26565/6d95da36729f4bc8925f9096bd52357b_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--left fs--30" content={"Ochrana výrobků <br>při přepravě<br>"}>
              </Title>

              <Text className="text-box mt--08" style={{"maxWidth":467}} content={"Výrobky z pěnového PE,<br>Výrobky z bublinkové fólie,<br>AIR program – sáčky a vícekomorové fólie plněné vzduchem včetně zařízení,<br>Výrobky z kartonáže, <br>Výrobky z kartonplastu<br>Papírové hrany<br>VCI vysoušedla\n<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1ekso57 --parallax pt--80" name={"kontakt"} style={{"paddingBottom":120}} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--48 ls--01" content={"<span style=\"color: var(--color-dominant);\">Svěřte balení svých výrobků do rukou experta.</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--20 ls--002" use={"page"} href={"/kontakt#99poxpvd2w9"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"footer"} style={{"marginTop":0,"paddingTop":43,"paddingBottom":35,"backgroundColor":"#f3f3f3"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--22" content={"GeneralPack s.r.o.<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334,"marginTop":32}} content={"Pražáková 1008/69<br>639 00 Brno<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--22" content={"Rychlý kontakt"}>
              </Title>

              <Text className="text-box text-box--right" style={{"marginTop":36}} content={"Email: info@generalpack.cz<br>Tel.: +420 604 741 713<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}